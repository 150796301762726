.markdownContainer {
  * {
    text-wrap: unset;
    word-break: break-word;
    @apply m-0;
  }

  h1, h2, h3 {
    @apply mt-1.5;
  }

  h4, h5, h6 {
    @apply mt-1;
  }

  li {
    list-style: decimal;
  }

  img {
    max-width: 100%;
    cursor: zoom-in;
  }

  pre {
    @apply my-1.5;
    @apply border rounded-lg hover:outline outline-1 outline-gray-300;
  }

  code {
    @apply p-0;
    font-size: 12px;
  }

  table, th, td {
    border: 1px solid;
    border-collapse: collapse;
    font-size: 13px;
  }

  th, td {
    @apply px-2 py-0.5;
  }

  th {
    @apply text-center;
  }

  a {
    @apply text-primary;
  }
}
